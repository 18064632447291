import React, { useEffect, useState, useRef } from 'react'
import Button from 'components/elements/Button';
import Input from 'components/elements/Input';
import Popup from 'components/popup/Popup';
import Store from 'store';
import { observer } from 'mobx-react';

import { HOST } from 'store/host';
import Request from 'store/Request.Utils';
import ImageEntity from './ImageEntity';

import _ from 'lodash';
import './ListImage.scss';
import PartnerPopup from './PartnerPopup';

const tabs = [
  'Partner',
  'Card Scheme',
  'Country',
  'Hotel Provider',
];



const ListImage = () => {
  const [showTab, setShowTab] = useState(tabs[0]);
  const [keyword, setKeyword] = useState('');
  const [parnerPopupData, setPartnerPopupData] = useState({
    show: false,
    data: {

    }
  })

  const allCountries = [
    ...Store.Country.listCountries.map(val => val.name),
    ...Store.Hotel.listCountries.map(val => val.country),
  ]

  const listCountry = _.uniqWith(allCountries, _.isEqual);
  const listPartner = Store.Country.getListPartnerFromCountryData();
  const listCardScheme = Store.Country.getListCardSchemeFromCountryData();
  const listHotelProvider = Store.Hotel.getListProviderFromCountryData();

  let listRender = [];
  switch(showTab) {
    case 'Country': listRender = listCountry; break;
    case 'Partner': listRender = listPartner; break;
    case 'Card Scheme': listRender = listCardScheme; break;
    case 'Hotel Provider': listRender = listHotelProvider; break;
    default:
  }

  const filteredList = (() => {
    if (!keyword) return listRender;
    return listRender.filter(val => {
      return val.toLowerCase().includes(keyword.toLowerCase());
    })
  })() 

  return (
    <div style={{ padding: 20 }}>
      <div style={{ padding: 5, marginBottom: 20 }}>
        {tabs.map((tab, i) => {
          const isActive = showTab === tab;
          return (
            <span
              key={'tab-header-'+i}
              onClick={() => setShowTab(tab)}
              style={{ display: 'inline-block', padding: 5, marginLeft:10, cursor: 'pointer', fontWeight: isActive ? 'bold' : 300, color: isActive ? 'var(--color-blue)' : 'black' }}>
              {tab}
            </span>
          )
        })}
      </div>
      <div className="row">
        <div className="col-md-9 col-12">
          <Input placeholder="type something to filter" name='Search' value={keyword} onChange={e => setKeyword(e.target.value)} />
        </div>
      </div>
      
      <div className="container-fluid">
        <div className="row">
          {filteredList.map((val, i) => (
            <div className="col-md-2 col-12 mb-2" key={'lisst-render-'+i} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
              <ImageEntity name={val} />
              <div
                className={showTab === 'Partner' ? 'partner-clickable' : ''}
                style={{ height: 40, textAlign: 'center', fontSize: 12, marginTop: 5, }}
                onClick={showTab !== 'Partner' ? undefined : () => {
                  // showPopupNotice();
                  setPartnerPopupData({
                    show: true,
                    data: {
                      name: val,
                    }
                  })
                }}
              >
                {val}
                {showTab === 'Partner' && (
                   <i className="fa fa-pencil" style={{ marginLeft: 10 }} />
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
      <PartnerPopup
        open={parnerPopupData.show}
        onClose={() => setPartnerPopupData({ show: false, data: {} })}
        data={parnerPopupData.data}
      />
    </div>
  );
};

export default observer(ListImage);