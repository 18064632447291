import Swal from 'sweetalert2';

type ShowInput = {
  message: string,
  html?: string,
  buttons?: {
    confirmButtonText: string,
    cancelButtonText: string,
    denyButtonText: string,
  },

}

class Popup {
  show = async ({ message, buttons, html } : ShowInput) => {
    const res = await Swal.fire({
      icon: 'info',
      text: !!html ? undefined : message,
      html: html,
      showConfirmButton: true,
      cancelButtonText: buttons?.cancelButtonText,
      confirmButtonText: buttons?.confirmButtonText || 'OK',
      showDenyButton: !!buttons?.denyButtonText,
      denyButtonText: buttons?.denyButtonText,
    })
    return {
      isConfirm: res.isConfirmed,
      isDenied: res.isDenied,
    }
  }

  showError = async ({ message, buttons } : ShowInput) => {
    const res = await Swal.fire({
      icon: 'error',
      text: message,
      showConfirmButton: true,
      cancelButtonText: buttons?.cancelButtonText,
      confirmButtonText: buttons?.confirmButtonText || 'OK',
      showDenyButton: !!buttons?.denyButtonText,
      denyButtonText: buttons?.denyButtonText,
    })
    return {
      isConfirm: res.isConfirmed,
      isDenied: res.isDenied,
    }
  }

  checkAndShowError = (res, err) => {
    let message = (() => {
      if (err) return String(err);
      if (!!res && res.error && res.message) {
        let messageStr = res.message;
        return messageStr;
      }
      return '';
    })();
    if (message) {
      this.showError({
        message,
      });
      return true;
    }
    return false;
  }
}

export default new Popup();