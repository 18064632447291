import React, { ReactNode } from 'react';
import { TypeSidebarMenuProps } from '../routers-define/types';
import SidebarItem from './SidebarItem';
import { Footer, Header } from 'components';
import Store from 'store';
import { observer } from 'mobx-react'; 

const SidebarContainer = (props : TypeSidebarMenuProps) => {

  const renderMenuItem = ():ReactNode => {
    return props.menu.map((item, index) => {
      return <SidebarItem key={`sidebarItem-${index}`} name={item.name} path={item.path} icon={item.icon} />
    })
  }


  // TO DO: Add Sidebar UI here
  // this is just demo idea
  return ( 
    <>
      <div id="wrap">
        <div id="main">
            <div style={{ width: 114, backgroundColor: '#242A41', position: 'fixed', bottom: 0, top: 0, left: 0, paddingTop: 50, zIndex: 2 }}>
              {renderMenuItem()}
            </div>
            <div style={{ flex: 1, marginLeft: 114}}>
              <Header user={{...Store.User.userData}}/>
              {props.children}
            </div>
          </div>    
      </div>
      < Footer/>
    </> 
  );
};



export default observer(SidebarContainer);