import React, { useEffect, useState, useRef } from 'react'
import Button from 'components/elements/Button';
import Input from 'components/elements/Input';
import Popup from 'components/popup/Popup';
import Store from 'store';
import { observer } from 'mobx-react';

import { HOST } from 'store/host';
import Request from 'store/Request.Utils';
import ImageEntity from './ImageEntity';

const Dashboard = () => {
  const [activeCountry, setActiveCountry] = useState('');
  const [keyword, setKeyword] = useState('');

  const filteredList = (() => {
    return Store.Hotel.listCountries.filter(val => {
      console.log('val', val);
      return val.country.toLowerCase().includes(keyword.toLowerCase()) || val.region.toLowerCase().includes(keyword.toLowerCase());
    })
  })().sort((a, b) => a.country > b.country ? 1 : -1);

  const uploadRef = useRef(null);

  const onUpload = async e => {
    const files = e.target.files;
    const [res, err] = await Store.Hotel.uploadExcel(files[0]);
    if (err) return console.log(err);
    if (res) {
      // console.log('onUpload', res);
      if (res.success) {
        window.location.reload();
      } else if (res.err === 'NOT_AUTHEN') {
        window.location.pathname = '/logout';
      } else {
        Popup.showError({
          message: String(res.err || 'Upload error'),
        })
      }
    }
  }

  const showPopupNotice = async () => {
    const res = await Popup.show({
      html: `Please make sure to use <a href="${HOST}/hotel/download-excel?token=${Request.getToken()}">this template file</a> and edit from there`,
      message: '',
      buttons: {
        confirmButtonText: "Ready to upload",
        cancelButtonText: "",
        denyButtonText: 'Continue editing',
      }
    });
    if (res.isConfirm) {
      uploadRef.current.click();
    }
  }

  return (
    <div style={{ padding: 20 }}>
      {/* <hr className="hr mt-2 mb-2" /> */}
      <div className="row">
        <div className="col-md-9 col-12">
          <Input placeholder="Search country" name='Search' value={keyword} onChange={e => setKeyword(e.target.value)} />
          <input onChange={onUpload} ref={uploadRef} type="file" style={{ display: 'none' }} />
        </div>
        <div className="col-md-3 col-12">
          <Button label="Upload excel" onClick={() => showPopupNotice()} />
        </div>
      </div>

      <div className="mt-2">
        {Array.isArray(filteredList)  && filteredList.map((val, i) => {
          const isActive = activeCountry === val.country
          return (
            <div
              key={'v-'+i} style={{ padding: 10, cursor: 'pointer', backgroundColor: isActive ? 'rgba(0,0,0,0.1)' : 'transparent' }}
              onClick={() => setActiveCountry(val.country)}
            >
              <p
                style={{
                  margin: 0,
                  fontWeight: isActive ? 'bold' : 300,
                  color: isActive ? 'var(--color-blue)' : 'rgba(0,0,0,0.8)',
                }}
              > 
                {val.country} ({val.region})
              </p>
              {isActive && (
                <div style={{ padding: 5 }}>
                  {val.hotelNet && val.hotelNet.length > 0 && (
                    <>
                      <h6>HOTEL NET RATES</h6>
                      {val.hotelNet.map((p, pI) => (
                        <div key={'p-'+pI+i} style={{ padding: 5, paddingLeft: 20, fontSize: 12 }}>
                          <span style={{ fontSize: 10 }}>Provider:</span> <span style={{ color: 'red', fontWeight: 'bold', fontSize: 20 }}>{p.name}</span>
                          {p.paymentOptions.map((c, cI) => {
                            return (
                              <div style={{ paddingLeft: 20 }} key={'c-'+cI+pI+i}>
                                <p style={{ fontSize: 12, margin: 5, color: 'red', fontWeight: 'bold' }}>
                                  <span style={{ fontSize: '1.5em', color: 'black', fontWeight: 'bold' }}>{c.id}</span> - {c.name} - {c.description}
                                </p>
                              </div>
                            )
                          })}
                        </div>
                      ))}
                    </>
                  )}
                  {val.hotelCommissionable && val.hotelCommissionable.length > 0 && (
                    <>
                      <h6>HOTEL COMMISSIONABLE RATES</h6>
                      {val.hotelCommissionable.map((p, pI) => (
                        <div key={'p-'+pI+i} style={{ padding: 5, paddingLeft: 20, fontSize: 12 }}>
                          <span style={{ fontSize: 10 }}>Provider:</span> <span style={{ color: 'red', fontWeight: 'bold', fontSize: 20 }}>{p.name}</span>
                          {p.paymentOptions.map((c, cI) => {
                            return (
                              <div style={{ paddingLeft: 20 }} key={'c-'+cI+pI+i}>
                                <p style={{ fontSize: 12, margin: 5, color: 'red', fontWeight: 'bold' }}>
                                  <span style={{ fontSize: '1.5em', color: 'black', fontWeight: 'bold' }}>{c.id}</span> - {c.name} - {c.description}
                                </p>
                              </div>
                            )
                          })}
                        </div>
                      ))}
                    </>
                  )}
                  {val.agencyNet && val.agencyNet.length > 0 && (
                    <>
                      <h6>AGENCY NET RATES</h6>
                      {val.agencyNet.map((p, pI) => (
                        <div key={'p-'+pI+i} style={{ padding: 5, paddingLeft: 20, fontSize: 12 }}>
                          <span style={{ fontSize: 10 }}>Provider:</span> <span style={{ color: 'red', fontWeight: 'bold', fontSize: 20 }}>{p.name}</span>
                          {p.paymentOptions.map((c, cI) => {
                            return (
                              <div style={{ paddingLeft: 20 }} key={'c-'+cI+pI+i}>
                                <p style={{ fontSize: 12, margin: 5, color: 'red', fontWeight: 'bold' }}>
                                  <span style={{ fontSize: '1.5em', color: 'black', fontWeight: 'bold' }}>{c.id}</span> - {c.name} - {c.description}
                                </p>
                              </div>
                            )
                          })}
                        </div>
                      ))}
                    </>
                  )}
                  {val.agencyCommissionable && val.agencyCommissionable.length > 0 && (
                    <>
                      <h6>AGENCY COMMISSIONABLE RATES</h6>
                      {val.agencyNet.map((p, pI) => (
                        <div key={'p-'+pI+i} style={{ padding: 5, paddingLeft: 20, fontSize: 12 }}>
                          <span style={{ fontSize: 10 }}>Provider:</span> <span style={{ color: 'red', fontWeight: 'bold', fontSize: 20 }}>{p.name}</span>
                          {p.paymentOptions.map((c, cI) => {
                            return (
                              <div style={{ paddingLeft: 20 }} key={'c-'+cI+pI+i}>
                                <p style={{ fontSize: 12, margin: 5, color: 'red', fontWeight: 'bold' }}>
                                  <span style={{ fontSize: '1.5em', color: 'black', fontWeight: 'bold' }}>{c.id}</span> - {c.name} - {c.description}
                                </p>
                              </div>
                            )
                          })}
                        </div>
                      ))}
                    </>
                  )}
                </div>
              )}
            </div>
          )
        })}
      </div>
    </div>
  );
};

export default observer(Dashboard);