import React from 'react';
import Store from 'store';
import { observer } from 'mobx-react';

const DetailA = () => {
  return (
    <div style={{ padding: 25 }}>
      
    </div>
  );
};

export default observer(DetailA);
