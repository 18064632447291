import React from 'react'
import './Footer.scss'
import defaultLogo from '../../../assets/img/logo.png'
import defaultAvatar from '../../../assets/img/avatar.png'


interface User {
  avatar?: string,
  name: string
}


interface HeaderProps {
  logo?: string,
  user?: User
}

const Footer = (props: HeaderProps) => {

  const { logo, user } = props

  return (
    <div className="footer-container">
      <div className="footer-logo">
        {/* <img src={logo ? logo : defaultLogo} alt=""/> */}
      </div>
    </div>
  )

}

export default (Footer)
