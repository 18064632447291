import React from 'react'
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'


import './Checkbox.scss'
import Store from 'store';
import { observer } from 'mobx-react';



interface Props {
  checked: boolean,
  disabled?: boolean,
  value: string,
  onChange: (value: string) => void,
  label: string
  labelStyle?: any
}


const Checkbox = (props: Props) => {

  const { checked, disabled, value, onChange, label, labelStyle } = props


  return (
    <div className={classnames(
      'nrp-checkbox-container',
      disabled ? 'disabled' : '',
    )} onClick={() => { !disabled && onChange(value)} }>
      <div className="nrp-checkbox">
        {checked && <FontAwesomeIcon icon={faCheck} color={Store.User.userData.companyColor !== '#FFFFFF' ? Store.User.userData.companyColor : '#0EA3DA'}/>}
      </div>
    <div className="nrp-checkbox-label" style={{...labelStyle}}>{label}</div>
    </div>
  )
}

export default observer(Checkbox)



