import { observer } from 'mobx-react';
import React, { useRef, useState } from 'react';
import Store from 'store';
import './ImageEntity.scss';

interface IProps {
  name: string,
  alwaysShowUploadIcon?: boolean,
  onUploading?: any,
  onUploadCompleted?: any,
  readOnly?: boolean,
}

const ImageEntity = (props : IProps) => {
  const [rerenderId, setRerenderId] = useState(0);
  const findImageIndex = Store.Site.listImages.findIndex(val => val.name === props.name);
  const entityUrl = findImageIndex != -1 ? Store.Site.listImages[findImageIndex].value : '';
  const inputRef = useRef(null);

  const onClickUpload = () => {
    inputRef.current?.click();
  }

  const onFileUpload = async e => {
    const file = e.target.files[0];
    if (!file) return;
    if (props.onUploading) props.onUploading();
    const res = await Store.Site.uploadImage(file, props.name);
    if (res.url) {
      if (findImageIndex != -1) {
        const listImages = Store.Site.listImages.slice();
        listImages[findImageIndex].value = res.url;
        Store.Site.listImages = listImages;
      } else {
        Store.Site.listImages = [
          ...Store.Site.listImages,
          {
            id: String(Math.random()),
            value: res.url,
            name: props.name,
          }
        ]
      }
      setRerenderId(Math.random())
    }
    if (props.onUploadCompleted) props.onUploadCompleted();
  }

  return (
    <div className="image-entity" style={{ width: 168, height: 112, position: 'relative' }}>
      <input type="file" style={{ display: 'none' }} ref={inputRef} onChange={onFileUpload} />
      {!props.readOnly && (
        <div className={`image-entity__upload-container ${props.alwaysShowUploadIcon ? 'show' : ''}`} onClick={onClickUpload}>
          <i className="fa fa-upload" />
        </div>
      )}
      {Boolean(!!entityUrl) && <img src={entityUrl} className="image-entity__content" />}
    </div>
  );
};

export default observer(ImageEntity);