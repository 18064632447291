import React from 'react';
import { Route, Switch, BrowserRouter } from 'react-router-dom';

import { routersDefinitionToReactRouterDomArray } from '../routers-define/convertToReactRouterDom';
import { TypeRoutersDefinition, TypeReactComponent, TypeReactRouterDomArray, TypeSidebarMenuProps, TypeDirectRoute } from '../routers-define/types';
import SidebarContainer from './SidebarContainer';

export const withSidebar = (routes : TypeRoutersDefinition, props : TypeSidebarMenuProps) : TypeDirectRoute => {

  let routesArray : TypeReactRouterDomArray = [];

  routersDefinitionToReactRouterDomArray(routes, routesArray);

  return {
    component: (cProps) => {
      // console.log('cProps', cProps);
      return (
        <SidebarContainer {...props}>
          <>
            {routesArray.map((val, i) => (
              <Route key={"sidebar-item-"+i} path={props.prefixPath ? props.prefixPath + val.path : val.path} component={val.component} exact {...val.customProps} />
            ))}
          </>
        </SidebarContainer>
      );
    }
  } 
}