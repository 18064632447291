import React from 'react'
import './Header.scss'
import defaultAvatar from '../../../assets/img/avatar.png'


interface User {
  photoUrl?: string,
  displayName: string
}


interface HeaderProps {
  logo?: string,
  user?: User 
}

const Header = (props: HeaderProps) => {

  const { logo, user } = props

  return (
    <div className="container-fluid header-container">
      <div className="header-logo">
      </div>
      {
        user && (
          <div className="user-area">
            <img src={user.photoUrl && user.photoUrl !== ''  ? user.photoUrl : defaultAvatar} alt=""/>
              <div>{user.displayName}</div>
          </div>
        )
      }
    </div>
  )

}

export default (Header)
