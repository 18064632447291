import React from 'react'
import './TextArea.scss'
import TextareaAutosize from 'react-textarea-autosize';


interface Props {
  onChange: (e: any) => void,
  placeholder: string,
  name: string
}

const TextArea = (props: Props) => {

  const { onChange, placeholder, name } = props

  return (
    <div>
      <TextareaAutosize name={name} className="nrp-textarea" minRows={10} placeholder={placeholder} onChange={onChange} />
    </div>
  )
}

export default (TextArea)