import { observable, autorun } from 'mobx';
import erria from 'erria/decorator';
import StoreClass, { StoreClassType } from './StoreClass.Utils';
import Request from './Request.Utils';
import { HOST } from './host';

import _ from 'lodash';

type GeneralResponse = (any|Error)[] | any;

interface IHotelContentProvider {
  name: string,
  paymentOptions: Array<{ name: string, id: string, description: string, comment: string }>,
}

export interface HotelSchema {
  id: string,
  country: string,
  region: string,
  hotelNet: Array<IHotelContentProvider>,
  hotelCommissionable: Array<IHotelContentProvider>,
  agencyNet: Array<IHotelContentProvider>,
  agencyCommissionable: Array<IHotelContentProvider>,
};

class Hotel extends StoreClass implements StoreClassType {
  constructor() {
    super();
    this.loadInitialData();
  }

  @observable listCountries : Array<HotelSchema> = [];

  async loadInitialData() {
    this.makeReady();
    this.getListData();
  }

  @erria
  async getListData() {
    const res = await Request.restGet(`${HOST}/hotel`, {});
    if (res.data.error) throw new Error(res.data.message);
    this.listCountries = res.data.list;
    return res.data;
  }

  getListProviderFromCountryData() {
    let result = [];
    this.listCountries.forEach(val => {
      val.hotelNet?.forEach(item => {
        result.push(item.name);
      }); 
      val.hotelCommissionable?.forEach(item => {
        result.push(item.name);
      }); 
      val.agencyNet?.forEach(item => {
        result.push(item.name);
      }); 
      val.agencyCommissionable?.forEach(item => {
        result.push(item.name);
      }); 
    });
    return _.uniqWith(result, _.isEqual);
  }
  
  @erria
  async uploadExcel(file) : Promise<GeneralResponse> {
    const body = new FormData()
    body.append('file', file);
    try {
      const res = await Request.postUpload(`${HOST}/hotel/upload-excel`, body);
      console.log(res.data);
      return res.data;
    } catch(err) {
      console.log(err);
    }
  }
}

export default new Hotel();