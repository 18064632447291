import { observable, autorun } from 'mobx';
import erria from 'erria/decorator';
import StoreClass, { StoreClassType } from './StoreClass.Utils';
import Request from './Request.Utils';
import { HOST } from './host';

import Protos from './protos/Protos.Utils';

type GeneralResponse = (any|Error)[] | any;

type PartnerName = 'Ixaris' | 'Airplus' | 'Barclaycard' | 'Apiso' | any;
type CardSchemeName = 'MC MBS (GWTTP) : GPS' | 'MC MRW (FALLBACK) : GPS' | 'VISA Business Debit : FIS' | 'UATP (Airplus)' | 'AIDA (Airplus)' | 'none' | any;

export interface CountrySchema {
  id: string,
  name: string,
  region: string,
  issurance: string,
  hasLocalIssuer: boolean,
  additionComments: string,
  partners: Array<{
    name: PartnerName,
    description: string,
    funding: string,
    currencies: Array<{ cardScheme: CardSchemeName, list: Array<string> }>,
  }>,
};

class Country extends StoreClass implements StoreClassType {
  constructor() {
    super();
    this.loadInitialData();
  }

  @observable listCountries : Array<CountrySchema> = [];

  async loadInitialData() {
    await Protos.onReady();
    this.makeReady();
    this.getListData();
  }


  @erria // only use this method for testing
  async getListHardcoded() {
    const res = await fetch(`${HOST}/country/json-hardcoded`);
    const json = await res.json();
    return json;
  }

  @erria // only use this method for testing
  async getListDataInJSON() {
    const res = await fetch(`${HOST}/country/json-from-db`);
    const json = await res.json();
    return json;
  }

  @erria
  async getListData() {
    const res = await Request.restGet(`${HOST}/country`, {}, Protos.Country.ListCountry);
    if (res.data.error) throw new Error(res.data.message);
    this.listCountries = res.data.list;
    // console.log('this.listCountries', this.listCountries);
    return res.data;
  }

  @erria
  async changePartnerName(data) {
    const res = await Request.restPost(`${HOST}/country/update-partner`, data);
    if (res.data.error) throw new Error(res.data.message);
    // console.log('this.listCountries', this.listCountries);
    return res.data;
  }

  @erria
  async importJson(data) {
    const res = await Request.restPost(`${HOST}/country/import-json`, data);
    if (res.data.error) throw new Error(res.data.message);
    // console.log('this.listCountries', this.listCountries);
    return res.data;
  }

  getListPartnerFromCountryData() {
    let result = [];
    this.listCountries.forEach(val => {
      if (!val || !val.partners) return;
      val.partners.forEach(p => {
        const name = p.name;
        if (!name) return;
        if (result.includes(name)) return;
        result.push(name);
      }); 
    });
    return result;
  }

  getListCardSchemeFromCountryData() {
    let result = [];
    this.listCountries.forEach(val => {
      if (!val || !val.partners) return;
      val.partners.forEach(p => {
        if (!p || !p.currencies) return;
        p.currencies.forEach(c => {
          const name = c.cardScheme;
          if (!name) return;
          if (result.includes(name)) return;
          result.push(name);
        })
      }); 
    });
    return result;
  }

  @erria
  async uploadExcel(file) : Promise<GeneralResponse> {
    const body = new FormData()
    body.append('file', file);
    try {
      const res = await Request.postUpload(`${HOST}/country/upload-excel`, body);
      console.log(res.data);
      return res.data;
    } catch(err) {
      console.log(err);
    }
  }
}

export default new Country();