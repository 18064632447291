import React, { useEffect, useState, useRef } from 'react'
import Button from 'components/elements/Button';
import Input from 'components/elements/Input';
import Popup from 'components/popup/Popup';
import Store from 'store';
import { observer } from 'mobx-react';
import { Modal } from 'react-responsive-modal';
import { HOST } from 'store/host';
import Request from 'store/Request.Utils';
import ImageEntity from './ImageEntity';
import PartnerPopup from './PartnerPopup'
import excel_import_instructions_image from './excel_import_instructions.png'
import './ConfirmImportPopup.scss';
import { on } from 'process';

const ConfirmImportPopup = ({ currencyList, listCountries, open, onClose }) => {
  const [activeCountry, setActiveCountry] = useState('');
  const [keyword, setKeyword] = useState('');

  const filteredList = (() => {
    if (!keyword) return listCountries.filter(val => val.hasLocalIssuer);
    return listCountries.filter(val => {
      if (!val.hasLocalIssuer) return false;
      return val.name.toLowerCase().includes(keyword.toLowerCase());
    })
  })().sort((a, b) => a.name > b.name ? 1 : -1);

  const confirmImport = async () => {
    const [res, err] = await Store.Country.importJson({
      data: listCountries,
      currencyList,
    });
    if (Popup.checkAndShowError(res, err)) return;
    onClose();
    await Popup.show({
        message: 'Data imported successfully',
    });
    window.location.reload();
  }

  return (
    <Modal open={open} onClose={onClose} center closeOnOverlayClick={false} styles={{
      modal: {
        width: 'max(300px, 60vw)',
        maxWidth: '1200px',
      }
    }}>
      <div style={{ padding: 20 }} className="confirm-import-container">
        <h4>Confirm data import</h4>
        <hr />
        {/* <hr className="hr mt-2 mb-2" /> */}
        <div className="row">
          <div className="col-md-9 col-12">
            <Input placeholder="Search country" name='Search' value={keyword} onChange={e => setKeyword(e.target.value)} />
          </div>
        </div>

        <div className="mt-2">
          {Array.isArray(filteredList) && filteredList.map((val, i) => {
            const isActive = activeCountry === val.name;
            return (
              <div
                key={'v-' + i} style={{ padding: 10, cursor: 'pointer', backgroundColor: isActive ? 'rgba(0,0,0,0.1)' : 'transparent' }}
                onClick={() => setActiveCountry(val.name)}
              >
                <p
                  style={{
                    margin: 0,
                    fontWeight: isActive ? 'bold' : 300,
                    color: isActive ? 'var(--color-blue)' : 'rgba(0,0,0,0.8)',
                  }}
                >
                  {val.name}
                  {!!val.issurance && isActive && (
                    <>
                      <br />
                      <span style={{ fontSize: 10, color: 'green' }}>Issurance: {val.issurance}</span>
                    </>
                  )}
                  {!!val.additionComments && isActive && (
                    <>
                      <br />
                      <span style={{ fontSize: 10, color: 'green' }}>Addition Comments: {val.additionComments}</span>
                    </>
                  )}
                </p>
                {isActive && (
                  <div style={{ padding: 5 }}>
                    {val.partners.map((p, pI) => {
                      return (
                        <div key={'p-' + pI + i} style={{ padding: 5, paddingLeft: 20, fontSize: 12 }}>
                          <span style={{ fontSize: 10 }}>Partner:</span> <span style={{ color: 'red', fontWeight: 'bold', fontSize: 14 }}>{p.name}</span>
                          <div style={{ margin: '10px 0px' }}>
                            <ImageEntity name={p.name} readOnly />
                          </div>
                          <div>
                            <p style={{ fontSize: 10, marginBottom: 3, }}>Description: {p.description}</p>
                            <p style={{ fontSize: 10, marginBottom: 3, }}>Funding: {p.funding}</p>
                          </div>
                          {p.currencies.map((c, cI) => {
                            if (c.list.length === 0) return null;
                            return (
                              <div style={{ paddingLeft: 20 }} key={'c-' + cI + pI + i}>
                                <p style={{ fontSize: 12, margin: 5, color: 'red', fontWeight: 'bold' }}>{c.cardScheme === 'none' ? '' : c.cardScheme}</p>
                                <div style={{ paddingLeft: 20, marginBottom: 10, fontSize: 12 }}>
                                  {c.list.join(', ')}
                                </div>
                              </div>
                            )
                          })}
                        </div>
                      )
                    })}
                  </div>
                )}
              </div>
            )
          })}
        </div>
        <hr />
        <div>
          <button onClick={confirmImport}>Confirm Import</button>
          <button className='cancel' onClick={onClose}>Cancel</button>
        </div>
      </div>
    </Modal>
  );
};

export default observer(ConfirmImportPopup);