import React from 'react';
import { Link, NavLink } from "react-router-dom";
import './SidebarItem.scss'
import Store from 'store';
import { observer } from 'mobx-react';


interface ISideBarItem {
  path: string,
  name: string,
  isActive?: boolean
  icon: any
}

const SidebarItem = (props : ISideBarItem) => {

  const {name, path, icon} = props

  const getUserStyle = () => {
    

    const {companyColor} = Store.User.userData
  
    switch (companyColor) {
      case '#000000':
        return 'black-hover'
      case '#0EA3DA':
        return ''
      case '#1300F4':
        return 'dark-blue-hover'
      default:
        return ''
    }
  }

  const userStyle = getUserStyle()

  return (    
    <NavLink to={path} className={`nrp-sidebar-item ${userStyle}`}>
        {icon()}
        <div style={{marginTop: 8}}>
          {name}  
        </div>
      </NavLink> 
  );
};

export default observer(SidebarItem);