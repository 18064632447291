import React from 'react';
import { Route, Switch, BrowserRouter } from 'react-router-dom';

import { TypeRoutersDefinition, TypeReactComponent, TypeReactRouterDomArray } from './types';

import { routersDefinitionToReactRouterDomArray } from './convertToReactRouterDom';

// to do: check if this function works

export const makeRoutes = (routes : TypeRoutersDefinition) : TypeReactComponent => {

  let routesArray : TypeReactRouterDomArray = [];

  routersDefinitionToReactRouterDomArray(routes, routesArray);

  return () => {
    return (
      <BrowserRouter>
        <Switch>
          {routesArray.map((val, i) => (
            <Route key={"comp-item-"+i} path={val.path} component={val.component} exact={val.exact} {...val.customProps} />
          ))}
        </Switch>
      </BrowserRouter>
    );
  }
}