import { TypeRoutersDefinition, TypeReactRouterDomArray } from './types';

export const routersDefinitionToReactRouterDomArray = (routes : TypeRoutersDefinition, routesArray : TypeReactRouterDomArray) => {
  const keysArray = Object.keys(routes);
  keysArray.forEach(endpoint => {
    if (routes[endpoint] === null) return;
    if (typeof routes[endpoint] === 'object' && !!routes[endpoint].component) {
      // DirectRoute
      routesArray.push({ path: endpoint, component: routes[endpoint].component, customProps: {}, exact: routes[endpoint].exact as boolean });
    } else if (typeof routes[endpoint] === 'object' && !routes[endpoint].component) {
      // Child Route - RoutersDefinition
      // @ts-ignore
      routersDefinitionToReactRouterDomArray(routes[endpoint], routesArray)
    }
  });
}