import React from 'react'
import classnames from 'classnames';
import './Input.scss' 
import Store from 'store';
import { observer } from 'mobx-react';

interface Props {
  name: string,
  value: string,
  placeholder?: string,
  type?: 'text' | 'password' | 'number',
  errorText?: string, 
  required?: boolean,
  style?: any,
  onChange: (newValue: any) => void
}


 
const Input = (props: Props) => {

  const {placeholder, name,  type, errorText, required, style, value, onChange} = props
  const {companyColor} = Store.User.userData

  return (
    <div>
      <input 
        className={classnames(
          'nrp-input',
          errorText ? 'nrp-input-error' : ''
        )} 
        name={name}       
        value={value}
        onChange={onChange}
        type={type ? type : 'text'} 
        placeholder={placeholder} 
        required={required ? required : false}
        style={{...style, borderBottomColor: companyColor}}
      />
      {errorText && <p className="nrp-input-error-text">{errorText}</p>}
    </div>
  )
}

export default observer(Input)
