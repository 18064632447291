import React, { useEffect, useState, useRef } from 'react'
import Button from 'components/elements/Button';
import Input from 'components/elements/Input';
import Popup from 'components/popup/Popup';
import Store from 'store';
import { observer } from 'mobx-react';

import { HOST } from 'store/host';
import Request from 'store/Request.Utils';
import ImageEntity from './ImageEntity';
import PartnerPopup from './PartnerPopup'
import excel_import_instructions_image from './excel_import_instructions.png'
import ConfirmImportPopup from './ConfirmImportPopup';

const Dashboard = () => {
  const [activeCountry, setActiveCountry] = useState('');
  const [keyword, setKeyword] = useState('');
  const [parnerPopupData, setPartnerPopupData] = useState({
    show: false,
    data: {

    }
  })
  const [confirmImportPopupData, setConfirmImportPopupData] = useState({
    show: false,
    data: {
      currencyList: [],
      listCountries: [],
    }
  })

  const filteredList = (() => {
    console.log('Store.Country.listCountries', Store.Country.listCountries);
    if (!keyword) return Store.Country.listCountries.filter(val => val.hasLocalIssuer);
    return Store.Country.listCountries.filter(val => {
      if (!val.hasLocalIssuer) return false;
      return val.name.toLowerCase().includes(keyword.toLowerCase());
    })
  })().sort((a, b) => a.name > b.name ? 1 : -1);

  const uploadRef = useRef(null);

  const onUpload = async e => {
    const files = e.target.files;
    const [res, err] = await Store.Country.uploadExcel(files[0]);
    if (err) return console.log(err);
    if (res) {
      // console.log('onUpload', res);
      if (res.success) {
        window.location.reload();
        // setConfirmImportPopupData({
        //   show: true,
        //   data: res.data,
        // });
      } else if (res.err === 'NOT_AUTHEN') {
        window.location.pathname = '/logout';
      } else {
        Popup.showError({
          message: String(res.err || 'Upload error'),
        })
      }
    }
  }

  const exportExcel = async () => {
    const res = await Popup.show({
      html: `Please click <a href="${HOST}/country/download-excel?token=${Request.getToken()}">here to export the latest data</a>. You can edit the data and upload it back here.`,
      message: '',
      buttons: {
        confirmButtonText: "OK",
        cancelButtonText: "",
        denyButtonText: '',
      }
    });
  }

  const showPopupNotice = async () => {
    // show popup saying it is under development
    // const res = await Popup.show({
    //   html: `This feature is under development. Please contact us for more information`,
    //   message: '',
    //   buttons: {
    //     confirmButtonText: "OK",
    //     cancelButtonText: "",
    //     denyButtonText: '',
    //   }
    // });

    const res = await Popup.show({
      html: `
        <p>In order to make data consistant, please make sure to use <a href="${HOST}/country/download-excel?token=${Request.getToken()}"> the latest exported file</a> and edit from there.
        The system also picks up new Partners/Cardschemes automatically</p>
        <div>
          <img src="${excel_import_instructions_image}" alt="excel import instructions" style="width: 100%; height: auto;" />
        </div>
      `,
      // html: `
      //   <p>The system will sync data daily from this <a href="https://1drv.ms/x/s!ArzSJ7E_kvyzgaMwfaMRSUeNAi5yJA?e=xCUAlF" target="_blank">Excel web file</a>. password: CD@#2024</p>
      //   <p>Manually importing data here will get override soon</p>
      // `,
      message: '',
      buttons: {
        confirmButtonText: "Ready to upload",
        cancelButtonText: "",
        denyButtonText: 'Cancel',
      }
    });
    if (res.isConfirm) {
      uploadRef.current.click();
    }
  }

  return (
    <div style={{ padding: 20 }}>
      {/* <hr className="hr mt-2 mb-2" /> */}
      <div className="row">
        <div className="col-md-9 col-12">
          <Input placeholder="Search country" name='Search' value={keyword} onChange={e => setKeyword(e.target.value)} />
          <input onChange={onUpload} ref={uploadRef} type="file" style={{ display: 'none' }} />
        </div>
        <div className="col-md-3 col-12">
          <Button label="Upload excel" onClick={() => showPopupNotice()} />
          <Button danger style={{ marginLeft: 10 }} label="Export excel" onClick={() => exportExcel()} />
        </div>
      </div>

      <div className="mt-2">
        {Array.isArray(filteredList)  && filteredList.map((val, i) => {
          const isActive = activeCountry === val.name;
          return (
            <div
              key={'v-'+i} style={{ padding: 10, cursor: 'pointer', backgroundColor: isActive ? 'rgba(0,0,0,0.1)' : 'transparent' }}
              onClick={() => setActiveCountry(val.name)}
            >
              <p
                style={{
                  margin: 0,
                  fontWeight: isActive ? 'bold' : 300,
                  color: isActive ? 'var(--color-blue)' : 'rgba(0,0,0,0.8)',
                }}
              > 
                {val.name}
                {!!val.issurance && isActive && (
                  <>
                    <br />
                    <span style={{ fontSize: 10, color: 'green' }}>Issurance: {val.issurance}</span>
                  </>
                )}
                {!!val.additionComments && isActive && (
                  <>
                    <br />
                    <span style={{ fontSize: 10, color: 'green' }}>Addition Comments: {val.additionComments}</span>
                  </>
                )}
              </p>
              {isActive && (
                <div style={{ padding: 5 }}>
                  {val.partners.map((p, pI) => {
                    return (
                      <div key={'p-'+pI+i} style={{ padding: 5, paddingLeft: 20, fontSize: 12 }}>
                        <span style={{ fontSize: 10 }}>Partner:</span> <span style={{ color: 'red', fontWeight: 'bold', fontSize: 14 }}>{p.name}</span>
                        {/* <i className="fa fa-pencil" style={{ marginLeft: 10, cursor: 'pointer' }}
                          onClick={() => {
                            setPartnerPopupData({
                              show: true,
                              data: {
                                name: p.name,
                                description: p.description,
                                funding: p.funding,
                                country: val.name,
                                currencies: p.currencies,
                              }
                            })
                          }}
                        /> */}
                        <div style={{ margin: '10px 0px' }}>
                          <ImageEntity name={p.name} />
                        </div>
                        <div>
                          <p style={{ fontSize: 10, marginBottom: 3, }}>Description: {p.description}</p>
                          <p style={{ fontSize: 10, marginBottom: 3, }}>Funding: {p.funding}</p>
                        </div>
                        {p.currencies.map((c, cI) => {
                          if (c.list.length === 0) return null;
                          return (
                            <div style={{ paddingLeft: 20 }} key={'c-'+cI+pI+i}>
                              <p style={{ fontSize: 12, margin: 5, color: 'red', fontWeight: 'bold' }}>{c.cardScheme === 'none' ? '' : c.cardScheme}</p>
                              <div style={{ paddingLeft: 20, marginBottom: 10, fontSize: 12 }}>
                                {c.list.join(', ')}
                              </div>
                            </div>
                          )
                        })}
                      </div>
                    )
                  })}
                  <button style={{ outline: 'none', border: 'none', marginLeft: 15, marginTop: 15 }}
                    onClick={() => {
                      setPartnerPopupData({
                        show: true,
                        data: {
                          name: '',
                          description: '',
                          funding: '',
                          country: val.name,
                          currencies: [],
                        }
                      })
                    }}
                  >
                    {/* <i className="fa fa-plus-circle" /> Add new Partner / Issuer */}
                  </button>
                </div>
              )}
            </div>
          )
        })}
      </div>
      <PartnerPopup
        open={parnerPopupData.show}
        onClose={() => setPartnerPopupData({ show: false, data: {} })}
        data={parnerPopupData.data}
      />
      <ConfirmImportPopup
        open={confirmImportPopupData.show}
        onClose={() => setConfirmImportPopupData({ show: false, data: {
          currencyList: [],
          listCountries: [],
        } })}
        currencyList={confirmImportPopupData.data.currencyList}
        listCountries={confirmImportPopupData.data.listCountries}
      />
    </div>
  );
};

export default observer(Dashboard);