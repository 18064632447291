import { Modal } from 'react-responsive-modal';
import React, { useEffect, useState } from 'react';
import { Popup } from 'components';
import ImageEntity from './ImageEntity';
import './PartnerPopup.scss';
import Store from 'store';

const PartnerPopup = ({ open, onClose, data }) => {
    console.log(data);
    const [newName, setNewName] = useState(data.name);
    const [newDescription, setDescription] = useState(data.description);
    const [newFunding, setNewFunding] = useState(data.funding);
    const [currencies, setCurrencies] = useState(data.currencies || []);
    const [updateNameForAllCountries, setUpdateNameForAllCountries] = useState(false);

    console.log('data', data);
    const [isLogoImageUploading, setIsLogoImageUploading] = useState(false);
    useEffect(() => {
        setNewName(data.name);
        setDescription(data.description)
        setNewFunding(data.funding)
        setCurrencies(data.currencies)
    }, [data]);

    const updatePartner = async () => {
        const [res, err] = await Store.Country.changePartnerName({
            oldName: data.name,
            newName,
            description: newDescription,
            funding: newFunding
        });
        if (Popup.checkAndShowError(res, err)) return;
        await Popup.show({
            message: 'Partner updated successfully',
        });
        window.location.reload();
    }

    const updatePartnerCountry = async () => {
        const [res, err] = await Store.Country.changePartnerName({
            oldName: data.name,
            newName,
            currencies,
            description: newDescription,
            funding: newFunding,
            country: data.country,
            updateNameForAllCountries,
        });
        if (Popup.checkAndShowError(res, err)) return;
        await Popup.show({
            message: 'Partner updated successfully',
        });
        window.location.reload();
    }

    const renderCurrencies = () => {
        return (
            <div className="currencies-container">
                <div className="currencies-row">
                    <div className="c-column logo">
                        Logo
                    </div>
                    <div className="c-column">
                        Card Scheme & Currencies<br />
                    </div>
                </div>
                {currencies && currencies.map((currency, index) => {
                    return (
                        <div className="currencies-row" key={'currency-' + index}>
                            <div className="c-column logo" style={{ paddingTop: 20, justifyContent: 'space-between' }}>
                                <ImageEntity name={currency.cardScheme} />

                                <i
                                    className='fa fa-trash'
                                    style={{ color: 'rgba(0,0,0,0.2)', fontSize: 20, cursor: 'pointer' }}
                                    onClick={() => {
                                        const newCurrencies = [...currencies];
                                        newCurrencies.splice(index, 1);
                                        setCurrencies(newCurrencies);
                                    }}
                                />
                            </div>
                            <div className="c-column">
                                <input value={currency.cardScheme} onChange={e => {
                                    const newCurrencies = [...currencies];
                                    newCurrencies[index].cardScheme = e.target.value;
                                    setCurrencies(newCurrencies);
                                }} />
                                <textarea
                                    value={(currency.list || []).join(', ')}
                                    onChange={e => {
                                        const newCurrencies = [...currencies];
                                        newCurrencies[index].list = e.target.value.split(', ').map(v => v.trim());
                                        setCurrencies(newCurrencies);
                                    }}
                                />
                            </div>
                        </div>
                    );
                })}
                <div className="currencies-row">
                    <div className="c-column logo">
                        <i
                            className="fa fa-plus-circle" onClick={() => {
                                const newCurrencies = [...currencies];
                                newCurrencies.push({ cardScheme: '', list: [] });
                                setCurrencies(newCurrencies);
                            }}
                            style={{
                                fontSize: 30,
                                transform: 'translateY(12px)'
                            }}
                        />
                    </div>
                    <div className="c-column">
                        <button
                            disabled={isLogoImageUploading}
                            onClick={updatePartnerCountry}
                            style={{ marginTop: 10 }}
                        >Save</button>
                    </div>
                </div>

            </div>
        )
    }

    return (
        <Modal open={open} onClose={onClose} center closeOnOverlayClick={false} styles={{
            modal: {
                width: 'max(300px, 60vw)',
            }
        }}>
            <div className='partner-popup-container'>
                <h4>Update partner / issuer{data.country ? <><br />{'for ' + data.country}</> : ''}</h4>
                <hr />
                <div className='logo-and-name'>
                    <div style={{ width: 200 }}>
                        <label style={{ marginBottom: 5 }}>1. Logo: </label>
                        <ImageEntity
                            name={data.name}
                            alwaysShowUploadIcon
                            onUploading={() => {
                                setIsLogoImageUploading(true);
                            }}
                            onUploadCompleted={() => {
                                setIsLogoImageUploading(false);
                            }}
                        />
                    </div>
                    <div>
                        <label>
                            <span>2. Name: </span>
                            <input
                                type="text" placeholder="Partner name" value={newName}
                                onChange={e => setNewName(e.target.value)}
                            />
                        </label>
                        {data.country && (
                            <>
                                {data.name !== '' && data.name !== newName && (
                                    <label style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', columnGap: 10 }}>
                                        <input
                                            type="checkbox" style={{ width: 15, height: 15, padding: 0 }}
                                            checked={updateNameForAllCountries}
                                            onChange={e => setUpdateNameForAllCountries(e.target.checked)}
                                        />
                                        <span style={{ fontSize: '0.8em' }}> also update partner name: {data.name} {'=>'} {newName} for all countries</span>
                                    </label>
                                )}
                                
                                <label>
                                    <span>3. Description: </span>
                                    <input
                                        type="text" placeholder="Description" value={newDescription}
                                        onChange={e => setDescription(e.target.value)}
                                    />
                                </label>
                                <label>
                                    <span>4. Funding: </span>
                                    <textarea
                                        placeholder="Funding" value={newFunding}
                                        onChange={e => setNewFunding(e.target.value)}
                                    />
                                </label>
                            </>
                        )}
                        {!data.country && (
                            <button
                                disabled={isLogoImageUploading || newName === data.name}
                                onClick={updatePartner}
                            >Save</button>
                        )}
                    </div>

                </div>
                {data.country && (
                    <>
                        <hr />
                        <div>
                            <label style={{ marginBottom: 5 }}>5. Currencies / Card Scheme:</label>
                        </div>
                        {renderCurrencies()}
                    </>
                )}
            </div>
        </Modal>
    );
};

export default PartnerPopup;