import { observable, autorun } from 'mobx';
import erria from 'erria/decorator';
import StoreClass, { StoreClassType } from './StoreClass.Utils';
import Request from './Request.Utils';
import { HOST } from './host';

import Protos from './protos/Protos.Utils';

type GeneralResponse = (any|Error)[] | any;

interface ImageSchema {
  id: string,
  name: string, // name of country / partners / card schemes
  value: string, // image url
}

class Site extends StoreClass implements StoreClassType {

  constructor() {
    super();
    this.loadInitialData();
  }

  @observable listImages: Array<ImageSchema> = [];

  async loadInitialData() {
    await Protos.onReady();
    this.makeReady();
    this.getListImage();
  }

  async access(password) {
    try {
      const res = await Request.post(`${HOST}/site/access`, { password }, { Req: Protos.Site.AccessRequest, Res: Protos.Site.AccessResponse});
      console.log(res.data);
    } catch(err) {
      console.log(err);
    }
  }

  async getListImage() {
    try {
      const res = await Request.restGet(`${HOST}/site/images`, { }, Protos.Site.ImageResponse);
      console.log(res.data);
      if (res.data.list) {
        this.listImages = res.data.list;
      }
      return res.data.list;
    } catch(err) {

    }
  }

  async uploadImage(file, name) {
    const body = new FormData()
    body.append('file', file);
    body.append('entity', name);
    try {
      const res = await Request.postUpload(`${HOST}/site/upload-image`, body);
      console.log(res.data);
      return res.data;
    } catch(err) {
      console.log(err);
    }
  }
}

export default new Site();