import React, { useState, useEffect } from 'react'
import { Button, Input, Form, Container, List, Header } from 'components'
import * as Yup from 'yup'
import Checkbox from 'components/elements/Checkbox'


const fieldProps = [
  {
    name: 'email',
    placeholder: 'Email',
    type: 'text' as 'text',
    initialValue: '',
    validateSchema: Yup.string().email('Email is not valid').required('Email is required')
  },
  {
    name: 'password',
    placeholder: 'Password',
    type: 'password' as 'password',
    initialValue: '',
    validateSchema:  Yup.string().required('Password is required')
  }
]

export default () => {

  const [nameInput, setnameInput] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [checkedValues, setIsCheckedValues] = useState([])
  

  const onInputChange = (e) => {
    setnameInput(e.target.value)
  }

  const onSubmit = (values) => {
    setIsLoading(true)
    console.log(values)
    setTimeout(() => {
      setIsLoading(false)
    }, 2000)
  }
  
  const onCheckBoxChange = (value) => {
    let valueList = [...checkedValues]
    if(valueList.includes(value)) {
      valueList = valueList.filter(el => el !== value)
    } else {
      valueList = [...valueList, value]
    }
    setIsCheckedValues(valueList)
  }


  return (
    <>
      <Header/>
      <Container>
        <div>
          <Button primary label={'Primary'} onClick={() => console.log('Hello')}/>
          <Button success label={'Success'} onClick={() => console.log('Hello')}/>
          <Button warning label={'Warning'} onClick={() => console.log('Hello')}/>
          <Button danger label={'Danger'} onClick={() => console.log('Hello')}/>
          <Button disabled label={'Disabled'} onClick={() => console.log('Hello')}/>
        </div>
        <div>
          <Button outline primary label={'Primary'} onClick={() => console.log('Hello')}/>
          <Button outline success label={'Success'} onClick={() => console.log('Hello')}/>
          <Button outline warning label={'Warning'} onClick={() => console.log('Hello')}/>
          <Button outline danger label={'Danger'} onClick={() => console.log('Hello')}/>
        </div>
        <Input name="Name" placeholder="Name" value={nameInput} onChange={onInputChange}/>
        <Input name="Password" placeholder="Password" type={"password"} value={nameInput} onChange={onInputChange}/>
        <Input name="number" placeholder="Number" type={"number"} value={nameInput} onChange={onInputChange}/>
        <Input name="Error" placeholder="Input with error" value={nameInput}  errorText={'Password is required'} required onChange={onInputChange}/>
        <div>
          <h2>Form</h2>
          <Form buttonLabel={'Submit'} fields={fieldProps} isLoading={isLoading} onSubmit={onSubmit}/>
        </div>
        <Checkbox label="A" value="A" checked={checkedValues.includes("A")} onChange={(value) => onCheckBoxChange(value)} />
        <Checkbox disabled label="B" value="B" checked={checkedValues.includes("B")} onChange={(value) => onCheckBoxChange(value)}/>

      </Container>
    </>
  );
};