import React from 'react'
import './Select.scss'
import Select from 'react-select'
import classnames from 'classnames';

interface SelectInputProps {
  styleType: 'default' | 'flat',
  placeholder: string
}

const SelectInput = (props) => {

  const {placeholder, styleType, errorText} = props

  return (
    <div>
      <Select className={classnames(
        'nrp-select',
        styleType === 'flat' ?'select-flat' : 'select-default'
      )} {...props}  classNamePrefix="react-select" placeholder={placeholder} />
      {errorText && <p className="nrp-input-error-text">{errorText}</p>}

    </div>

  )

}

export default (SelectInput)

