import update from 'immutability-helper';
import CryptoJS from 'crypto-js';

const GlobalEvent = require('js-events-listener');

const Dot = require('dot-object');
const md5 = require('md5');

const clientEncode = (string) => {
  return CryptoJS.AES.encrypt(string, 'f1f9b8c8580a69273737a153602de6c9c97ed95d095d7bf734124a9c567a7871').toString();
};

const clientDecode = (string) => {
  return CryptoJS.AES.decrypt(string, 'f1f9b8c8580a69273737a153602de6c9c97ed95d095d7bf734124a9c567a7871').toString(CryptoJS.enc.Utf8);
};

if (typeof window === 'undefined') {
  var window : any = {
    localStorage: {
      getItem: () => '',
      setItem: () => undefined,
    },
  };
}

class StorageDependency {

  lib = !window ? {
    getItem: () => '',
    setItem: () => undefined,
  } : localStorage

}

export const storageDependency = new StorageDependency();

class Persist {

  save = async (value, asyncStoreItem) => {
    if (!storageDependency.lib) return;
    if (value === undefined || value === null) return;
    const valueType = typeof value;
    const stringValue = valueType !== 'object' ? String(value) : JSON.stringify(value);
    const oldDecyptedValue = await storageDependency.lib.getItem(md5(asyncStoreItem));
    if (oldDecyptedValue !== null) {
      const oldValue = clientDecode(oldDecyptedValue);
      if (oldValue === stringValue) return;
    }
    await storageDependency.lib.setItem(md5(asyncStoreItem), clientEncode(stringValue));
    storageDependency.lib.setItem('last_updated', String(new Date().getTime()));
  }

  sync = async (asyncStoreItem, type) => {
    let value = await storageDependency.lib.getItem(md5(asyncStoreItem));
    if (value == null) {
      return undefined;
    }
    value = clientDecode(value);
    if (type === 'string') return value;
    if (type === 'number') return Number(value);
    if (type === 'object' || type === 'array') {
      try {
        let parsed = JSON.parse(value);
        return parsed;
      } catch (err) {
        return undefined;
      }
    }
    if (type === 'boolean') return value === 'true';
  }
}

export interface StoreClassType {
  persist: any,
  getValue(label: string): any,
  setValue(label: string, value: any): any,
  makeReady(): any,
  onReady(callback : any): Promise<any>,
  [additionMethods : string]: any, 
}

export default class StoreClass {

  persist = new Persist();

  getValue = (label) => {
    if (!label.includes('.')) {
      return this[label];
    }

    const parts = label.split('.');
    let data = this[parts[0]];
    for (let i = 0; i < parts.length - 1; i++) {
      data = data[parts[i + 1]];
    }
    return data;
  }

  setValue = (label, value) => {
    if (!label.includes('.')) {
      this[label] = value;
      return;
    }
    const statePropertyName = label.substring(0, label.indexOf('.'));
    const stateCurrentValue = this[statePropertyName];

    // create new stateValue
    let dotPartArray = label.split('.');
    dotPartArray.shift();
    let updateSchemaDefined = { [dotPartArray.join('.')]: { $set: value } };
    const stateNewValue = update(stateCurrentValue, Dot.object(updateSchemaDefined));

    this[statePropertyName] = stateNewValue;
  }

  id = Math.floor(Math.random() * 1000000) + '' + Math.floor(Math.random() * 1000000);

  ready = false;

  makeReady = ( ) => {
    this.ready = true;
    GlobalEvent.emit("STORE_READY_" + this.id);
  }
  onReady = (callback = undefined) => new Promise((resolve, reject) => {
    const run = () => {
      typeof callback === 'function' && callback();
      resolve();
    }
    if (this.ready) run();
    else GlobalEvent.on("STORE_READY_" + this.id, run);
  });
}