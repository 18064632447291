import React, { useState, useRef } from 'react'
import { Form, Popup } from 'components'
import {Link} from 'react-router-dom'
import * as Yup from 'yup'
import imgAmadeusLogo from '../../../assets/img/amadeus.png'
import './Login.scss'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Store from 'store';
import {useHistory} from 'react-router-dom'

const fieldProps = [
  {
    name: 'email',
    placeholder: 'Email Address',
    type: 'text' as 'text',
    initialValue: '',
    validateSchema: Yup.string().email('Email is not valid').required('Email is required')
  },
  {
    name: 'password',
    placeholder: 'Password',
    type: 'password' as 'password',
    initialValue: '',
    validateSchema:  Yup.string().required('Password is required')
  }
]

export default () => {

  let history = useHistory()


  const [isLoading, setIsLoading] = useState(false)

  const onSubmit = async (values) => {
    setIsLoading(true)
    const [res, err] = await Store.User.login({
      email: values.email,
      password: values.password  
    })
    setIsLoading(false);
    if (Popup.checkAndShowError(res, err)) return;
    history.push('/admin/dashboard');
  }


  return (
    <div className="screen-container login-screen">
      <div className="screen-overlay">
        <div className="login-container">
          <div className="text-center">
            <img src={imgAmadeusLogo} alt="Amadeus"/>
          </div>
          <Form buttonLabel={'Login'} fields={fieldProps} isLoading={isLoading} onSubmit={onSubmit} buttonClassName={"login-button"}>
            <div className="forgot-password-link">
              <Link to="/forgot-password">Forgotten Password</Link>
            </div>
          </Form>
        </div>
      </div>
      <ToastContainer/>

    </div>
  )
}
