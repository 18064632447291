import React, { useState, useEffect } from 'react';
import Router from 'navigation/router';
import Store from 'store';
import 'react-responsive-modal/styles.css';


function App() {
  const [storeReady, setStoreReady] = useState(false);

  useEffect(() => {
    Store.User.onReady().then(() => {
      console.log('Store.User.onReady');
      // console.log('Store.User.isAuth()', Store.User.isAuth());
      setStoreReady(true);
    })
  }, []);

  // only load the application after the User Store is ready (token is synced from local)
  if (!storeReady) return null;

  return (
    <Router />
  );
}

export default App;
