import User from './User.Store';
import Country from './Country.Store';
import Site from './Site.Store';
import Hotel from './Hotel.Store';

import Protos from './protos';
import Request from './Request.Utils'

const md5 = require('md5');

const Store = {
  User,
  Country,
  Site,
  Hotel,
};

if (!!window) {
  // @ts-ignore
  window.enableDevMode = password => {
    if (md5(password) === 'de65b7a591c05787f63a9287bc202587') {
      // @ts-ignore
      window.Store = Store;
      // @ts-ignore
      window.Protos = Protos;
      // @ts-ignore
      window.Request = Request;
    }
  };
}

export default Store;