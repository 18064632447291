import React, { ReactElement } from 'react'
import './SortableList.scss'
import {SortableContainer, SortableElement} from 'react-sortable-hoc';
import { BsGrid3X2Gap } from "react-icons/bs";
import Store from 'store';
import { observer } from 'mobx-react';


const SortableItem = SortableElement((props) => props.children);


interface ColumnProps {
  key: string,
  name: string,
  renderProps?: (records: any) => ReactElement
}

interface ListProps {
  columns: ColumnProps[],
  dataSource: any[],
  onSortEnd: any
}

export const SortableList = observer((props: ListProps) => {
  const {columns, dataSource} = props
  return (
    <div className="nrp-sortable-list">
        <table className="table">
          <thead>
            <tr>
              <th></th>
              {
                columns.map(el => {
                  return <th key={el.key}>{el.name}</th>
                })
              }
            </tr>
          </thead>
          <tbody>
            {
              dataSource.map((item, index) => {
                return (
                  <SortableItem index={index}>
                    <tr>
                      <td>           
                        <BsGrid3X2Gap size={24} color={Store.User.userData.companyColor} style={{marginRight: 10, cursor: 'pointer'}}/>
                      </td>
                      {
                        columns.map(el => {
                          if(el.renderProps) {
                            return <td>{el.renderProps(item)}</td>
                          }
                          return <td key={`${el.key}_${index}`}>{item[el.key]}</td>
                        })
                      }
                    </tr>
                   </SortableItem>
                )
              })
            }
          </tbody>
        </table>
    </div>
  )
 
})

export default SortableContainer(SortableList)


