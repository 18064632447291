import React, {useEffect} from 'react'
import classnames from 'classnames';
import Store from 'store';
import './Button.scss'
import { observer } from 'mobx-react';
import { Spinner } from 'react-bootstrap';


interface Props {
  label: string,
  onClick: () => void,
  outline?: boolean,
  // color?: string,
  disabled?: boolean,
  primary?: boolean,
  success?: boolean,
  danger?: boolean,
  warning?: boolean,  
  // TODO loading
  style?: any,
  className?: string,
  loading?: boolean
}

const Button = (props: Props) => {

  const {label, onClick,  outline, style, disabled, className, primary, danger, success, warning, loading } = props

  const getUserStyle = () => {

    const {companyColor} = Store.User.userData
  
    switch (companyColor) {
      case '#FFFFFF':
        return {
          background: '#FFFFFF',
          borderColor: '#333333',
          color: '#333333'
        }
        
      case '#0EA3DA':
        return {
          background: '#0EA3DA',
          borderColor: '#0EA3DA',
          color: '#FFFFFF'
        }     
      case '#1300F4':
        return {
          background: '#1300F4',
          borderColor: '#1300F4',
          color: '#FFFFFF'
        }     
      case '#000000':
        return {
          background: '#000000',
          borderColor: '#000000',
          color: '#FFFFFF'
        }     
      default:
        return {}
    }
  }

  const userStyle = getUserStyle()

  return  (
    <div
      className={classnames(
        'nrp-button',
        outline ? 'outline' : 'solid',
        primary? 'primary' : '',
        warning? 'warning' : '',
        danger? 'danger' : '',
        success? 'success' : '',
        disabled ? 'disabled' : '',
        className
      )}    
      style={{...style, ...userStyle}} onClick={!disabled &&  onClick}>
        {loading && <Spinner variant="light" size="sm" animation="border" style={{marginRight: 5}} />}{label}       
    </div>
  )
}
 
export default observer(Button);