import React from 'react'
import classnames from 'classnames';
import './Form.scss'

import { Formik, Field, Form, FormikHelpers } from 'formik';
import Input from '../Input';
import * as Yup from "yup";
import Button from '../Button';
import SelectInput from '../Select';
import TextArea from '../TextArea';


interface FormField {
  name: string,
  placeholder?: string,
  type: 'text' | 'password' | 'number' | 'textarea' |'select',
  selectStyle?: 'flat' | 'default',
  selectOptions?: any[],
  initialValue?: string | number | any,
  validateSchema: any,
}


interface FormProps {
  fields: FormField[],
  onSubmit: (values: any) => void,
  isLoading: boolean,
  submitButtonStyles?: any
  children?: any,
  buttonLabel: string
  buttonClassName?: string  
}


export default (props: FormProps) => {

  const {fields, onSubmit, isLoading, submitButtonStyles, buttonLabel, buttonClassName } = props

  const getinitialValues = () => {
    let initialValues = {}
    fields.map(el => {
      initialValues = {...initialValues, [`${el.name}`]:  el.initialValue}
    })
    return initialValues
  }

  const onSubmitForm = (values) => {
    console.log(values)
    onSubmit(values)
  }

  const validationSchema = () => {
    let validateObject = {}
    fields.map(el => {
      validateObject = {...validateObject,[`${el.name}`]: el.validateSchema}
    })
    return Yup.object().shape(validateObject)
  }

  return (
    <>
      <Formik 
        initialValues={getinitialValues()} 
        onSubmit={onSubmitForm}
        validationSchema={validationSchema()}
        validateOnChange={false}
        enableReinitialize
      >
        {({ values, errors, handleChange, handleSubmit, setFieldValue}) => (
          <form encType="multipart/form-data" >
            {
              fields.map((item, index) => {
                if(item.type === 'select') {
                  return <SelectInput onChange={option => {
                    console.log(values[`${item.name}`])
                    console.log(option)
                    // console.log(item.initialValue)
                    
                    setFieldValue(item.name, option)
                  }}
                  defaultValue={item.initialValue}
                  options={item.selectOptions} placeholder={item.placeholder} styleType={item.selectStyle} errorText={errors[`${item.name}`] as string}/>
                }
                if(item.type === 'textarea') {
                  return <TextArea name={item.name} onChange={handleChange} placeholder={item.placeholder}/>
                }
                return <Input name={item.name} onChange={handleChange} value={values[`${item.name}`]} placeholder={item.placeholder} type={item.type} errorText={errors[`${item.name}`] as string}/>
              })
            }
            {props.children}
            <Button loading={isLoading} label={buttonLabel} onClick={handleSubmit} disabled={isLoading} className={buttonClassName}/>
          </form>
        )}
        </Formik>
    </>
  )
}
