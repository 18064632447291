import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee } from '@fortawesome/free-solid-svg-icons'

import {
  ListingA, DetailA, CreateA,
  Login, Logout, // authentication
  ListImage, // dashboard
  Wallet,
  Hotel
} from 'screens';

import { generateRouteNames } from './routers-define/generateRouteNames';
import { makeRoutes } from './routers-define/makeRoutes';
import { makeScreens } from './screens-factory/makeScreens';
import { userSchema, somethingSchema } from './modelSchema';
import { withSidebar } from './sidebar/withSidebar';

import {Redirect} from 'react-router'

import Store from 'store';

import iconDashboard from '../assets/icon/dashboard.png';
import iconAdmin from '../assets/icon/admin.png';
import iconLogout from '../assets/icon/logout.png';
import iconDoc from '../assets/icon/theme.png';

const doTheUpdate = async foo => Promise.resolve();
const doCustomThingWithTheResponse = async foo => Promise.resolve();

const withAuth =  (WrapComponent) => {

  if(Store.User.isAuth()) {
    return WrapComponent
  } 
  return <Redirect to="/login"/>

}

const sidebarDefine = [
  { path: '/admin/wallet', name: 'Wallet', icon: () => <img src={iconDashboard} width="24" alt=""/>},
  { path: '/admin/hotel', name: 'Hotel', icon: () => <img src={iconDashboard} width="24" alt=""/>},
  { path: '/admin/list-image', name: 'Images', icon: () => <img src={iconDoc} width="24" alt=""/> },
  { path: '/logout', name: 'Logout', icon: () => <img src={iconLogout} width="24" alt=""/> },
];

export default makeRoutes({

  '/': {
    component: (props) => {
      return <Redirect to="/admin/wallet" />
    },
    exact: true,
  },
  '/login': {
    component: (props) => {
      return <Login {...props}/>
    }
  },
  '/logout': {
    component: (props) => {
      return <Logout {...props}/>
    }
  },

  // Add later
  '/admin': withSidebar({

    '/wallet': {
      component: (props) => {
        return withAuth(<Wallet {...props}/>)
      }
    },
    '/hotel': {
      component: (props) => {
        return withAuth(<Hotel {...props}/>)
      }
    },
    '/list-image': {
      component: (props) => {
        return withAuth(<ListImage {...props}/>)
      }
    },

  }, { menu: sidebarDefine, prefixPath: '/admin' }),
})