import React, { useEffect, useState } from 'react';
import { Popup } from 'components';
import { Redirect } from 'react-router-dom';
import Store from 'store';

const Logout = () => {

  const [didCallAPI, setDidCallAPI] = useState(false);

  useEffect(() => {
    (async () => {
      const [res, err] = await Store.User.logout();
      if (Popup.checkAndShowError(res, err)) return;
      setDidCallAPI(true);
    })()
  }, []);

  if (!didCallAPI) return null;

  return (
    <Redirect to="/login" />
  )
};

export default (Logout);